import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { formatBalance } from "_common/utils/formatValue";
import icon1 from "assets/img/icon/recharge1.svg";
import icon2 from "assets/img/icon/withdraw1.svg";
import icon3 from "assets/img/icon/exchange1.svg";
import icon4 from "assets/img/icon/transfer1.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { faRotate, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const General = observer(() => {
  const {
    authStore: { display_balance, setDisplayBalance, user, tokens },
  } = useStores();

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "vnd"),
    [user]
  );

  return (
    <div id="general">
      <div className="flex flex-col w-full gap-10 ">
        <div className="flex flex-1">
          <div className="flex flex-col w-full gap-4">
            <div className="allmoneybox">
              <div className="allmbox_2">
                <span className="fzmm fcc text-white">
                  {i18next.t("account_balance")}(USDT)
                </span>
                <span
                  className="ml-[10px]"
                  onClick={() => setDisplayBalance(!display_balance)}
                >
                  <FontAwesomeIcon
                    icon={display_balance ? faEye : faEyeSlash}
                  />
                </span>
                <span
                  className="ml-[10px]"
                  onClick={() => window.location.reload()}
                >
                  <FontAwesomeIcon icon={faRotate} />
                </span>
              </div>
              <div className="allmbox_3 mt-[25px]">
                <span className="zhehebox text-[26px] text-white">
                  {formatBalance(usdtBalance?.amount, display_balance)}
                </span>
              </div>
              <div className="allmbox_btn">
                <div className="allmbox_btn_1">
                  <Link to="/member/transfer">
                    <div className="allmbox_tbtn ">
                      <img src={icon4} alt="" width="23" className="mr-[5px]" />
                      <span className="text-white">
                        {i18next.t("transfer")}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default General;
